// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YyX6ue9l{align-items:center;background-color:var(--color-white);border:1Px solid var(--color-boulder);border-radius:0.14285714rem;display:flex;justify-content:center;min-height:1.14285714rem;min-width:1.14285714rem}.YyX6ue9l:after{color:var(--color-white);display:block;font-size:0.85714286rem;padding-top:0.07142857rem}.YyX6ue9l:hover{background-color:hsla(var(--color-background-primary-h), var(--color-background-primary-s), var(--color-background-primary-l), 0.05);border:1px solid var(--color-mine-shaft)}.NpkkoicS{background-color:var(--color-background-primary)}.NpkkoicS:after{content:"";font-family:"lpdi-webfont-icons";position:relative}.NpkkoicS:hover{background-color:var(--color-background-primary)}.YdVDri1f{border:1Px solid hsla(var(--color-mine-shaft-h), var(--color-mine-shaft-s), var(--color-mine-shaft-l), 0.5)}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `YyX6ue9l`,
	"checkbox__checked": `NpkkoicS`,
	"checkbox__disabled": `YdVDri1f`
};
export default ___CSS_LOADER_EXPORT___;
