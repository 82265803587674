import * as React from 'react';

import {
	ChangeEvent,
	FocusEvent,
	MutableRefObject,
	Ref,
	RefObject,
	useRef,
	useState,
} from 'react';

// ENUMS
import {
	EnumComponentType,
} from '@enums/component.enum';
import {
	EnumInputPosition,
	EnumInputType,
} from '@enums/form.enum';

//STYLES
import styles from './input.module.scss';

type register = Partial<{
	hookRef?: MutableRefObject<HTMLInputElement>;
	name?: string;
	pattern?: string;
	ref?: RefObject<HTMLInputElement>;
	rules?: {
		ref?: RefObject<HTMLInputElement>;
		required?: {
			message?: string;
			value?: boolean;
		};
		pattern?: {
			message?: string;
			value?: string | RegExp;
		};
		onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
		onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
		onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
	};
}>;

interface MethodsProps {
	register?: (name?: string, rules?: object) => register;
	formState?: {
		errors?: {
			[key: string]: string | undefined;
			name?: string;
		};
	};
	watch?: (name?: string) => unknown;
	setError?: (name?: string) => void;
	setValue?: (name: string, value: unknown) => void;
	clearErrors?: (name?: string) => void;
}

interface MessageProps {
	message?: string;
}

interface InputPropsCommon {
	autoComplete?: string;
	autoFocus?: boolean;
	checked?: boolean;
	className?: string;
	customError?: string;
	'data-testid'?: string;
	defaultValue?: string | string[];
	dirty?: boolean;
	disabled?: boolean;
	error?: MessageProps;
	hasBorder?: boolean;
	hasShadow?: boolean;
	id?: string;
	invalid?: MessageProps;
	initialValue?: string;
	inputPosition?: EnumInputPosition;
	label?: string;
	name?: string;
	pattern?: string;
	placeholder?: string;
	required?: boolean;
	tabIndex?: number;
	validationType?: string;
	value?: string | string[] | number;
	type?: EnumInputType;
}

interface InputProps extends InputPropsCommon {
	innerRef?: Ref<HTMLInputElement>;
	maxLength?: number;
	methods?: MethodsProps;
	onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
	onChange?: (event: ChangeEvent<HTMLInputElement>, newState?: object) => void;
	onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
	onInput?: (event: FocusEvent<HTMLInputElement>) => void;
	registerRules?: register['rules'];
}

const Input = ({
	autoFocus = false,
	className,
	'data-testid': dataTestid,
	hasBorder = true,
	hasShadow = false,
	id,
	innerRef,
	invalid,
	maxLength = 255,
	methods,
	name,
	onBlur,
	onChange,
	onFocus,
	registerRules,
	type = EnumInputType.TEXT,
	...otherProps
}: InputProps): JSX.Element => {
	const initialState = {
	};

	const [
		state,
		setState
	] = useState(initialState);

	const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
		if (onBlur) onBlur(event);
	};

	const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
		const newState = {
			...state,
		};
		setState(newState);

		if (onChange) onChange(event, newState);
	};

	const handleOnFocus = (event: FocusEvent<HTMLInputElement>) => {
		if (onFocus) onFocus(event);
	};

	const isControled = methods?.register;
	let register = null;
	let rules: register['rules'] = {
	};

	if (isControled && name && rules) {
		rules.onChange = handleOnChange;
		rules.onBlur = handleOnBlur;
		rules.onFocus = handleOnFocus;

		// ONLY WITH REACT HOOK FORM
		/* istanbul ignore next */
		if (registerRules) {
			rules = {
				...registerRules,
				...rules,
			};
		}
		register = isControled(name, rules);
	}
	const hookRef = useRef();
	const localRef = innerRef || hookRef;

	const cssClasses = [
		styles.input
	];
	if (className) cssClasses.push(className);
	if (hasBorder) cssClasses.push(styles.border);
	if (hasShadow) cssClasses.push(styles.shadow);

	const isInvalid = invalid ? 'true' : 'false';
	return (
		<input
			{...otherProps}
			id={id}
			ref={localRef as Ref<HTMLInputElement> | undefined}
			onBlur={handleOnBlur}
			onChange={handleOnChange}
			onFocus={handleOnFocus}
			{...register}
			aria-invalid={isInvalid}
			autoFocus={autoFocus}
			className={cssClasses.join(' ')}
			data-testid={dataTestid}
			maxLength={maxLength}
			name={name}
			type={type}
		/>
	);
};

Input.displayName = EnumComponentType.INPUT;

export {
	Input as default,
	InputProps,
	InputPropsCommon,
	MessageProps,
};

