// TYPES
import {
	ObjectKeyStringValueString,
} from '@@types/Settings';
import {
	ApiResponse,
} from '@@types/Api';

export interface Utils {
	getURL(
		path: string,
		args?: ObjectKeyStringValueString): string;
	getQueryParams(url: string): string[];
}

export const getURL = (path: string, args?: ObjectKeyStringValueString): string => {
	let result = `${path}`;

	if (args && Object.keys(args).length) {
		Object.keys(args).forEach(argKey => {
			result = result.replace(argKey, args[argKey]);
		});
	}
	return result;
};

export const getQueryParams = (inputString: string = ''): string[] => {
	let queryString = '';
	let queryParams: string[] = [
	];
	if (inputString.includes('?')) {
		queryString = inputString.split('?')[1];
	}
	if (queryString.length && queryString.includes('=')) {
		queryParams = queryParams.concat(queryString.split('&'));
	}

	return queryParams;
};

// Utility function to handle API responses
export const handleErrorResponse = (response: ApiResponse<Record<string, unknown>>, ErrorClass: new (message: string) => Error): void => {
	const successStatus = [
		200,
		201,
		204
	];
	if (!successStatus.includes(response.status)) {
		throw new ErrorClass(response.statusText);
	}
};
