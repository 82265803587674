import React, {
	useEffect,
	useRef,
	useState,
	MouseEvent,
} from 'react';
import {
	useTranslation,
	withTranslation,
} from 'react-i18next';
import {
	useParams,
} from 'react-router-dom';
import {
	Markup,
} from 'interweave';

// TYPES
import {
	ContactDemand,
} from '@@types/ContactDemand';
import {
	FieldValue,
	FieldValueJson,
} from '@@types/FieldValue';
import {
	Image,
} from '@@types/Image';
import {
	Lot,
} from '@@types/Lot';
import {
	Offer,
} from '@@types/Offer';

// ENUMS
import {
	EnumButtonCorners,
	EnumButtonSize,
} from '@enums/button.enum';
import {
	EnumFontStyle,
} from '@enums/font.enum';
import {
	EnumStatusTheme,
	EnumTheme,
} from '@enums/theme.enum';
import {
	EnumTargetLink,
} from '@enums/link.enum';
import {
	EnumModalSize,
} from '@enums/modal.enum';

// CONFIG
import {
	APP_CONF_VARS,
	EnumImageFormatQuery,
} from '@appConf/vars.conf';

// MODULES
import getOfferPrice from '@modules/getOfferPrice';
import {
	updateImageUrls,
} from '@modules/images';

// ROUTES
import PATHS from '@routes/paths';

// DATAS
import getColumns from '@static_data/area-table.data';

// LAYOUTS
import {
	BodyRequest,
} from '@layouts/Pages/PageDemand/config/demand.data';
import ContentLoaderSlider from './contentLoaderSlider';
import ContentLoaderHistory from './contentLoaderHistory';
import {
	GetCardsConfiguration,
} from '@layouts/Pages/PageOfferDetails/config/offer-action';
import ModalSlider from '@layouts/Modals/ModalSlider';
import ModalDefault from '@layouts/Modals/ModalDefault';
import {
	isContentLoadedProps,
} from '@layouts/Pages/PageDemand/manage';

// COMPONENTS
import ActionBar from '@components/action-bar';
import Button, {
	ButtonProps,
} from '@components/button';
import ButtonList, {
	ButtonListProps,
} from '@components/button-list';
import ButtonWithDropdown, {
	ButtonWithDropdownProps,
} from '@components/button-with-dropdown';
import Cartography from '@components/cartography';
import {
	DropdownItemProps,
} from '@components/dropdown/dropdown-item';
import Feature from '@components/feature';
import Icon, {
	IconProps,
} from '@components/icon';
import LabelStatus, {
	LabelStatusProps,
} from '@components/label-status';
import Link from '@components/link';
import Modal, {
	ModalProps,
} from '@components/modal';
import Paragraph from '@components/paragraph';
import Slider, {
	SliderProps,
} from '@components/slider';
import Table from '@components/table';
import Tooltip from '@components/tooltip';
import UserCard, {
	ThemeUserCard,
} from '@components/user-card';

// STYLES
import styles from './PageOfferDetails.module.scss';

interface PageOfferDetailsProps {
	configurationActionBar?: {
		type?: string;
		actions?: JSX.Element;
		commercializationStep?: string;
		demandId?: number;
	};
	customPlaceholder?: SliderProps['customPlaceholder'];
	data?: Offer;
	isContentLoaded?: isContentLoadedProps;
	isDesktopResolution?: boolean;
	isEventHistoryLoaded?: boolean;
	isPrivate?: boolean;
	onClickApplication?: (args: object) => void;
	onClickCommercializationStep?: (event: MouseEvent<HTMLElement>, args: BodyRequest) => void;
	onClickModal?: (event: MouseEvent<HTMLElement>, args: BodyRequest) => void;
}

interface ModalPropsState extends ModalProps {
	items?: SliderProps['items'];
}

function PageOfferDetails({
	configurationActionBar,
	customPlaceholder,
	data,
	isContentLoaded,
	isDesktopResolution,
	isEventHistoryLoaded,
	isPrivate = false,
	onClickCommercializationStep,
	onClickModal,
}: PageOfferDetailsProps) {
	const offerDetailLegacy = `${APP_CONF_VARS.sfLegacyPath}/offer/detail/id/${data?.id}`;
	const { offerid } = useParams();
	const { t } = useTranslation();
	const [
		isOpenState,
		setIsOpenState
	] = useState(false);
	const [
		modalPropsState,
		setModalPropsState
	] = useState(null as ModalPropsState);
	const cssClassesActionBar = [
		styles.action_bar__container,
		'animation',
	];
	const [
		cssClassesActionBarState,
		setCssClassesActionBarState
	] = useState(cssClassesActionBar);

	useEffect(() => {
		if (offerid && data) {
			setCssClassesActionBarState([
				...cssClassesActionBarState,
				'animation__appearing'
			]);
		} else {
			setCssClassesActionBarState(cssClassesActionBar);
		}
	}, [
		offerid
	]);

	const handleOnClickTriggerModal = (event: MouseEvent<HTMLElement>, modal: ModalPropsState) => {
		event.stopPropagation();
		setModalPropsState({
			...modal
		} as ModalPropsState);
		setIsOpenState(true);
	};

	const cssClasses = [
		styles.PageOfferDetails,
	];
	configurationActionBar?.actions ? cssClasses.push(styles.actions_displayed) : undefined;

	const getListItemsElements = (collection: FieldValue[], translationKey = 'references') => {
		return (
			<ul className={styles.text_list}>
				{collection.map((item: FieldValue, id: number) => {
					const itemTextFormated = t('format.capitalize', {
						text: item.text
					});
					let itemText = null;
					itemText = item.text ? <Markup content={itemTextFormated} /> : null;
					if (item.values?.length) {
						if (typeof item.values[0] === 'string') {
							itemText = item.values.map((value: string) => {
								const generalTranslationKey = `general.${value}`;
								const announcementTranslationKey = `${translationKey}.announcement.${value}`;
								const naturesTranslationKey = `${translationKey}.natures.${value}`;
								return t('format.capitalize', {
									text: t([
										announcementTranslationKey,
										naturesTranslationKey,
										generalTranslationKey,
										value
									])
								});
							}).join(', ');
						} else if (typeof item.values[0] === 'object') {
							const fieldValueInstance = item.values.map((fieldValue: FieldValueJson) => {
								return new FieldValue(fieldValue);
							});
							itemText = getListItemsElements(fieldValueInstance);
						}
					}
					return (
						<li key={id}>
							{item.label ? (
								<span className={styles.text_list__label}>
									{
										t('format.capitalize', {
											text: t([
												`${translationKey}.announcement.${item.label}`,
												`${translationKey}.natures.${item.label}`,
												item.label,
											])
										})
									}
									&nbsp;:&nbsp;
								</span>
							) : null}
							{itemText ? (
								<span className={styles.text_list__value}>{itemText}</span>
							) : null}
						</li>
					);
				})}
			</ul>
		);
	};

	const offerDetailsTitleAddress = data?.building?.address?.full ? <div className={styles.PageOfferDetails__title}>{data.building.address.full}</div> : null;

	const offerDetailsTitle = data?.title ? <div className={styles.PageOfferDetails__title}>{data.title}</div> : null;

	let transaction_types_text = null;
	let salePriceFormated = null;
	let rentalPriceFormated = null;

	if (data?.transaction_types?.length) {
		if (data?.transaction_types?.length > 1) {
			transaction_types_text = `${t(`general.${data.transaction_types[0]}`)} ${t('general.and')} ${t(`general.${data.transaction_types[1]}`)}`;
		} else {
			transaction_types_text = t(`general.${data.transaction_types[0]}`);
		}
	}
	const transactionTypeFeature = (
		<div className={styles.transaction_type}>
			{t('format.capitalize', {
				'text': transaction_types_text
			})}
		</div>
	);

	let areaLotFeature = undefined;
	if (data.lots_resume?.area_min && data.lots_resume?.area_max) {
		if (data.lots_resume?.area_min === data.lots_resume?.area_max) {
			areaLotFeature = t('page.demand.offer.detail.spread_lot_unique', {
				'%count%': data.lots_resume?.count,
				'%max_area%': t('format.area', {
					'val': data.lots_resume?.area_max,
				}),
			});
		} else {
			areaLotFeature = t('page.demand.offer.detail.spread_lot', {
				'%count%': data.lots_resume?.count,
				'%max_area%': t('format.area', {
					'val': data.lots_resume?.area_max,
				}),
				'%min_area%': t('format.area', {
					'val': data.lots_resume?.area_min,
				})
			});
		}
	}

	const areaBuildingFeature = data?.building?.area?.value ? (
		<span className={styles.area_building}>
			{
				t('format.area', {
					'val': data.building.area.value,
				})
			}
		</span>
	) : null;

	if (data?.sale_price_value?.amount || data?.sale_price_state) {
		const salePrice = getOfferPrice(data?.sale_price_value, data?.sale_price_state);
		salePriceFormated = salePrice.amount ? (
			<>
				<span
					className={styles.price__amount}
				>
					{salePrice.amount}
				</span>
				{salePrice.unit}
				{salePrice.chargesText}
			</>
		) : salePrice.state;
	}

	if (data?.rental_price_value?.amount || data?.rental_price_state) {
		const rentalPrice = getOfferPrice(data?.rental_price_value, data?.rental_price_state);
		rentalPriceFormated = rentalPrice.amount ? (
			<>
				<span
					className={styles.price__amount}
				>
					{rentalPrice.amount}
				</span>
				{rentalPrice.unit}
				{rentalPrice.chargesText}
			</>
		) : rentalPrice.state;
	}

	const firstLetterRent = t('format.capitalize', {
		text: t('general.rent')
	}).charAt(0);
	const firstLetterSale = t('format.capitalize', {
		text: t('general.sale')
	}).charAt(0);

	let priceformated = null;
	if (rentalPriceFormated && salePriceFormated) {
		priceformated = <><div className={styles.price__item}>{rentalPriceFormated} {`(${firstLetterRent})`}</div><div className={styles.price__item}>{salePriceFormated} {`(${firstLetterSale})`}</div></>;
	} else if (rentalPriceFormated || salePriceFormated) {
		priceformated = <div>{rentalPriceFormated || salePriceFormated}</div>;
	}

	const priceFeature = priceformated ? (
		<div
			className={styles.price}
		>
			{priceformated}
		</div>
	) : null;

	const minimumCharge = (
		<div className={styles.small_text}>(
			{t('format.capitalize', {
				text: t('general.charge_minimum')
			})})
		</div>
	);

	const chargeValue = t('format.capitalize', {
		text: t('format.tax_type.excluded_tax', {
			text: t('format.per.square_meter_per_year', {
				text: t('format.price', {
					val: data?.charge?.amount
				})
			})
		})
	});

	const noCharge = t('format.capitalize', {
		text: t('general.charge_undetermined')
	});

	const chargeFeature = data?.charge ? (
		<div
			className={styles.charge}
		>
			{data?.charge ?
				(data?.charge?.amount === 0 ? noCharge : (
					<>{chargeValue}{minimumCharge}</>
				)) : null}
		</div>
	) : undefined;

	const areaFeature = areaBuildingFeature || areaLotFeature ? (
		<>
			{areaBuildingFeature} {areaLotFeature}
		</>
	) : null;
	const availabilityState = data?.availability.state ? t(`status.${data.availability.state}`) : null;
	const availabilityDate = availabilityState || data?.availability.date_txt || data?.availability.date ? t('format.capitalize', {
		text: t('general.availability_text', {
			'text': availabilityState || data?.availability.date_txt || data?.availability.date
		})
	}) : null;

	const commercializedDate = data?.commercialized_at ?
		t('format.capitalize', {
			text: t('general.commercialized_at', {
				'text': data.commercialized_at
			})
		}) : null;

	const featuresListElement = (
		<Paragraph
			className={styles.features_list}
			hasBorder={true}
		>
			<div className={styles.features_list__title}>
				{t('format.capitalize', {
					text: t('page.demand.offer.detail.features')
				})}
			</div>
			{transactionTypeFeature ? (
				<Feature
					className={`${styles.features_list__item} ${styles.transaction_type}`}
					icon='key'
				>
					{transactionTypeFeature}
				</Feature>
			) : null}
			{priceFeature || chargeFeature ? (
				<Feature
					className={`${styles.features_list__item} ${styles.price}`}
					icon='euro-sign'
				>
					{priceFeature}
					{chargeFeature}
				</Feature>
			) : null}
			{areaFeature ? (
				<Feature
					className={`${styles.features_list__item} ${styles.area}`}
					icon='area'
				>
					{areaFeature}
				</Feature>
			) : null}
			{commercializedDate || availabilityDate ? (
				<Feature
					className={`${styles.features_list__item} ${styles.availability_date}`}
					icon='calendar-day'
				>
					{commercializedDate || availabilityDate}
				</Feature>
			) : null}
		</Paragraph>
	);

	const iconsList = [
	];

	if (data?.is_private) {
		iconsList.push({
			className: styles.icon_element,
			id: `iconPrivate-${data.id}`,
			name: 'lock',
			style: EnumFontStyle.SOLID,
			tooltip: t('format.capitalize', {
				text: t('general.private', {
					context: 'female'
				})
			}),
		});
	}

	if (data?.is_added) {
		iconsList.push({
			className: styles.icon_element,
			id: `iconManualAdd-${data.id}`,
			name: 'hand-sparkles',
			style: EnumFontStyle.SOLID,
			tooltip: t('format.capitalize', {
				text: t('general.added_manually', {
					context: 'female'
				})
			}),
		});
	} else if (data && !data.is_search_criterias_matched) {
		iconsList.push({
			className: styles.icon_element,
			id: `iconOutOfCriteria-${data.id}`,
			name: 'search-slash',
			style: EnumFontStyle.SOLID,
			tooltip: t('format.capitalize', {
				text: t('general.out_of_criteria')
			}),
		});
	}

	const offerDetailRef = t('format.capitalize', {
		text: t('page.demand.offer.detail.ref', {
			'id': data?.id
		})
	});

	const referencialElement = isPrivate ? (
		<Link
			className={styles.ref}
			href={offerDetailLegacy}
			target={EnumTargetLink.BLANK}
		>
			{offerDetailRef}
		</Link>
	) : offerDetailRef;

	let mandateTheme = null;
	switch (true) {
		case data?.mandate?.type === 'exclusive' && data?.mandate.is_owner:
			mandateTheme = EnumStatusTheme.SUCCESS;
			break;
		case data?.mandate?.type === 'exclusive':
			mandateTheme = EnumTheme.ELEVATION;
			break;
		case data?.mandate?.type === 'simple':
			mandateTheme = EnumStatusTheme.WARNING;
			break;
		case data?.mandate?.type === 'regulation':
			mandateTheme = EnumStatusTheme.ERROR;
			break;
	}

	const mandate = mandateTheme ? {
		type: t(`general.mandate.${data.mandate.type}`),
		theme: mandateTheme
	} : null;

	const mandateElement = mandate?.type ? (
		<div className={styles.mandate_type}>
						&nbsp;-&nbsp;
			<LabelStatus
				className={styles.label_status}
				hasBackground={false}
				text={mandate.type}
				theme={mandate.theme as LabelStatusProps['theme']}
			/>
		</div>
	) : null;

	const referencialMandateElement = (
		<div className={styles.referencial}>
			{referencialElement}
			{isPrivate ? mandateElement : null}
			{isPrivate && iconsList?.length ? (
				<div className={styles.icons_container}>
					{iconsList.map((icon, key: number) => {
						return (
							<Tooltip
								className={icon.className}
								content={icon.tooltip}
								id={icon.id}
								key={key}
							>
								<Icon
									fontStyle={icon.style as IconProps['fontStyle']}
									name={icon.name}
								/>
							</Tooltip>
						);
					})}
				</div>
			) : null}
		</div>
	);

	const descriptionElement = data?.description ? (
		<Paragraph
			className={styles.paragraph}
			title={t('format.capitalize', {
				text: t('page.demand.offer.detail.description')
			})}
		>
			<Markup content={data?.description} />
		</Paragraph>
	) : null;

	const availableCategory = [
		'photo'
	];
	const photos = data?.images?.collection?.length ? data.images.collection.filter((image: Image) => availableCategory.includes(image.category)) : null;

	const cssSlider = [
		styles.slider,
	];

	if (photos?.length && !customPlaceholder) cssSlider.push(styles.slider__selected);

	const sliderStatesTopLeft = [
		'commercialized',
		'unavailable',
	];

	const getSliderLabels = (definedLabels: string[], scopeLabels: string[]) => {
		const labelsFormated: LabelStatusProps[] = [
		];
		definedLabels?.filter(defineLabel => {
			if (scopeLabels.includes(defineLabel.toLowerCase())) {
				const labelStatus = {
					context: 'female',
					text: defineLabel.toLowerCase()
				};
				labelsFormated.push(labelStatus);
			}
		});
		return labelsFormated;
	};

	const images = data.images?.collection?.length ? data.images.collection.filter(image => availableCategory.includes(image.category)) : null;
	const slider: SliderProps = {
		infinite: true,
		items: images,
		labelsTopLeft: getSliderLabels(data.states, sliderStatesTopLeft),
		size: 'thumbnail',
	};

	const sliderElement = (
		<div className={styles.PageOfferDetails__header__left}>
			<Slider
				className={cssSlider.join(' ')}
				customPlaceholder={customPlaceholder}
				infinite={true}
				items={photos?.length ? updateImageUrls(photos, isDesktopResolution ? EnumImageFormatQuery.SLIDER : EnumImageFormatQuery.SLIDER_MOBILE) as Image[] : null}
				labelsTopLeft={slider?.labelsTopLeft}
				onClick={!photos?.length || customPlaceholder ? null : (event) => handleOnClickTriggerModal(event, {
					hasHeader: false,
					items: photos,
					size: isDesktopResolution ? EnumModalSize.DEFAULT : EnumModalSize.FULL
				} as ModalPropsState)}
			/>
			{isDesktopResolution ? null : offerDetailsTitleAddress}
			{isDesktopResolution ? null : offerDetailsTitle}
			{isDesktopResolution ? null : featuresListElement}
		</div>
	);

	const servicesElement = data?.services?.length ? (
		<Paragraph
			className={styles.paragraph}
			title={t('format.capitalize', {
				text: t('page.demand.offer.detail.services')
			})}
		>
			{getListItemsElements(data.services)}
		</Paragraph>
	) : null;

	const financialTermsElement = data?.financial_terms?.length ? (
		<Paragraph
			className={styles.paragraph}
			title={t('format.capitalize', {
				text: t('page.demand.offer.detail.financial_terms')
			})}
		>
			{getListItemsElements(data.financial_terms)}
		</Paragraph>
	) : null;

	const legalTermsElement = data?.legal_terms?.length ? (
		<Paragraph
			className={styles.paragraph}
			title={t('format.capitalize', {
				text: t('page.demand.offer.detail.legal_terms')
			})}
		>
			{getListItemsElements(data.legal_terms)}
		</Paragraph>
	) : null;

	const availableCategoryPlan = [
		'plan'
	];
	const plans = data?.images?.collection?.length ? data.images.collection.filter((image: Image) => availableCategoryPlan.includes(image.category)) : null;

	const areaTableColumnsConf = getColumns(data);

	const areaTableDataConf = [
	] as object[];
	data?.lots?.collection?.forEach((lot: Lot) => {
		let lotAvailabilityDateState = null;

		switch (lot?.availability?.state) {
			case 'under_option':
			case 'under_promise':
				lotAvailabilityDateState = (
					<LabelStatus
						className={styles.availability_state_label}
						hasBackground={false}
						text={lot?.availability?.state}
						theme={EnumStatusTheme.WARNING}
					/>
				);
				break;
			case 'suspended':
				lotAvailabilityDateState = (
					<LabelStatus
						className={styles.availability_state_label}
						hasBackground={false}
						text={lot?.availability?.state}
						theme={EnumStatusTheme.ERROR}
					/>
				);
				break;
			case 'immediate_availability':
				lotAvailabilityDateState = (
					<LabelStatus
						className={styles.availability_state_label}
						hasBackground={false}
						text={lot?.availability?.state}
						theme={EnumTheme.ELEVATION}
					/>
				);
		}
		const displayedNatures = lot?.natures?.length ? (
			lot.natures.map((nature: string) => {
				return t('format.capitalize', {
					text: t(`references.natures.${nature}`)
				});
			}).join(' - ')
		) : null;

		const lotData = {
			'building': lot?.building ? lot?.building : '-',
			'lot': lot?.reference ? lot?.reference : '-',
			'floor': lot?.floor ? lot?.floor : '-',
			'nature': displayedNatures ? displayedNatures : '-',
			'area': lot?.area?.value ? t('format.area', {
				val: lot?.area?.value
			}) : '-',
			'rental_price': lot?.rentalPrice?.price?.amount ? lot.rentalPrice.price.amount : '-',
			'sale_price': lot?.salePrice?.price?.amount ? lot?.salePrice.price.amount : '-',
			'availability': lotAvailabilityDateState || lot?.availability?.date_txt || lot?.availability?.date,
		};
		areaTableDataConf.push(lotData);
	});

	const areasElement = (
		<Paragraph
			className={styles.paragraph}
			title={t('format.capitalize', {
				text: t('page.demand.offer.detail.areas')
			})}
		>
			<Table
				className={styles.table}
				columns={areaTableColumnsConf}
				data={areaTableDataConf}
				identifier={`table_offer_${data?.id}`}
				options={{
					initialSort: [
						{
							'id': 'building',
							'desc': false
						}
					],
					sort: [
						'building',
						'lot',
						'floor'
					],
					pagination: {
						nbItemsPerPage: isDesktopResolution ? 5 : 2
					}
				}}
				theme='column'
			/>
			{plans?.length ? (
				<Button
					corners={EnumButtonCorners.Square}
					iconRight='plan'
					iconStyle={EnumFontStyle.REGULAR}
					label={t('format.capitalize', {
						text: t(`general.view_plan${plans?.length > 1 ? '_other' : ''}`, {
							'%count%': plans.length
						})
					})}
					size={EnumButtonSize.SMALL}
					theme={EnumTheme.SECONDARY}
					onClick={(event) => handleOnClickTriggerModal(event, {
						hasHeader: false,
						items: plans,
						size: isDesktopResolution ? EnumModalSize.DEFAULT : EnumModalSize.FULL
					} as ModalPropsState)}
				/>
			) : null}
		</Paragraph>
	);

	const itemsContact = [
	] as JSX.Element[];

	if (data.contacts?.collection.length) {
		data.contacts.collection.forEach((contact: ContactDemand, index: number) => {
			const createdUserCardData = {
				title: contact.full_name,
				id: contact.id.toString(),
				image_url: updateImageUrls(contact?.logo?.url, EnumImageFormatQuery.USERCARD) as string || undefined,
				descriptions: [
					{
						value: contact.company_name,
						type: 'subtitle',
						required: true,
					},
					{
						value: contact.phone || contact.mobile,
						type: 'tel',
						required: true,
					},
					{
						value: contact.email,
						type: 'email',
						required: true,
					}
				],
			};
			const itemContact = (
				<UserCard
					className={styles.user_card}
					data={createdUserCardData}
					key={index}
					theme={ThemeUserCard.SQUARE}
					visitData={contact?.suggested_contact_for_visit}
				/>
			);
			itemsContact.push(itemContact);
		});
	}

	const mandatedAgentsElement = isPrivate ? (
		<Paragraph
			className={styles.paragraph}
			title={t('format.capitalize', {
				text: t('page.demand.offer.detail.mandated_agent', {
					count: data.contacts?.collection?.length
				})
			})}
		>
			<div className={styles.mandated_agents}>
				<>
					{data.contacts?.collection?.length ? itemsContact : t('format.capitalize', {
						text: t('general.awaiting_mandate')
					})}
				</>
			</div>

		</Paragraph >
	) : null;

	const energyPowerDiagnosticElement = (
		<Paragraph
			className={styles.paragraph}
			title={t('format.capitalize', {
				text: t('page.demand.offer.detail.energy_performance_diagnostic')
			})}
		>
			{data?.energy_performance_diagnostic?.length ?
				getListItemsElements(data.energy_performance_diagnostic)
				: t('format.capitalize', {
					text: t('references.announcement.dpe_in_progress')
				})}
		</Paragraph>
	);

	const transportAccessElement = data?.transport_service ? (
		<Paragraph
			className={styles.paragraph}
			title={t('format.capitalize', {
				text: t('page.demand.offer.detail.transport_service')
			})}
		>
			<Markup content={data?.transport_service} />
		</Paragraph>
	) : null;

	let modalTemplate = undefined;

	if (data.event_history?.collection?.length) {
		modalTemplate = (
			<ModalDefault>
				<div className={styles.modal_content}>
					{referencialMandateElement}
					<ul className={styles.list}>
						{data.event_history.collection.map((item, key: number) => {
							return (
								<li
									className={styles.list__element}
									key={key}
								>
									{t('format.capitalize', {
										text: t(`page.demand.${item.event}`, {
											'%date%': item.date
										})
									})}
								</li>
							);
						})}
					</ul>
				</div>
			</ModalDefault>
		);
	}

	if (modalPropsState?.items) {
		modalTemplate = (
			<ModalSlider
				items={modalPropsState?.items}
			/>
		);
	}

	const modalElement = (
		<Modal
			hasHeader={modalPropsState?.hasHeader}
			isDesktopResolution={isDesktopResolution}
			isOpen={isOpenState}
			size={modalPropsState?.size}
			title={modalPropsState?.title}
			onClose={() => {
				setIsOpenState(false);
			}}
		>
			{modalTemplate}
		</Modal>
	);

	const mapRef = useRef(null);
	const feature = data?.building?.geo_json ? {
		...data?.building?.geo_json,
		properties: {
			offer_id: data.id
		}
	} : null;
	const cartographyElement = feature ? (
		<Cartography
			className={styles.map}
			config={APP_CONF_VARS.mapbox.config}
			copyright={{
				href: PATHS._ROOT,
				text: t('website.copyright'),
			}}
			geojsonSource={{
				name: 'offers',
				data: feature
			}}
			innerRef={mapRef}
		/>
	) : null;

	const getButtonItemsOfferActions = (itemList: ButtonProps[] | ButtonWithDropdownProps[]) => {
		const result = itemList?.map((item: ButtonProps | ButtonWithDropdownProps) => {
			const buttonCssClasses = [
				styles.button
			];
			if (item.className) buttonCssClasses.push(item.className);
			const updatedState = {
				badgeTheme: null as EnumStatusTheme,
				iconLeft: (item as ButtonProps).iconLeft,
				label: (item as ButtonProps).label,
				tooltipText: (item as ButtonProps).label,
			};
			switch (true) {
				case data?.states?.includes('visit_planned') && (item as DropdownItemProps).id === 'plan_my_visit':
					updatedState.iconLeft = 'calendar-rotate';
					updatedState.tooltipText = t('format.capitalize', {
						text: t('general.action.update_visit')
					});
					updatedState.label = t('format.capitalize', {
						text: t('general.action.update_visit')
					});
					break;
				case data?.states?.includes('visit_expired') && (item as DropdownItemProps).id === 'plan_my_visit':
					updatedState.iconLeft = 'calendar-question';
					updatedState.tooltipText = t('format.capitalize', {
						text: t('general.action.confirm_visit')
					});
					updatedState.label = t('format.capitalize', {
						text: t('general.action.confirm_visit')
					});
					updatedState.badgeTheme = EnumStatusTheme.ERROR;
					break;
			}

			return {
				...item,
				...updatedState,
				className: buttonCssClasses.join(' '),
				label: isDesktopResolution ? null : updatedState.label,
				hasBoxShadow: false,
				tooltipText: isDesktopResolution ? updatedState.tooltipText : null,
				onClick: item.onClick ? (event: MouseEvent<HTMLElement>) => {
					item.onClick(event, data);
				} : null,
			};
		});
		return result;
	};
	// END GET BUTTONS FROM LIST FUNCTION
	const configurationOfferActions = isPrivate && configurationActionBar?.demandId ? GetCardsConfiguration(data, isDesktopResolution, configurationActionBar.demandId, configurationActionBar.commercializationStep, onClickModal, onClickCommercializationStep) : null;
	const actionSelectedBarElement = isPrivate ? (
		<div className={cssClassesActionBarState.join(' ')}>
			<ActionBar
				className={styles.action_bar__content}
			>
				{isDesktopResolution ? (
					<ButtonList
						className={styles.action_offer}
						items={getButtonItemsOfferActions(configurationOfferActions as ButtonListProps['items'])}
						tooltipIsActive={true}
					/>
				) : (
					<ButtonWithDropdown
						className={styles.action_button_dropdown}
						classNameDropdown={styles.dropdown_mobile}
						dropdownTheme={EnumTheme.SECONDARY}
						hasBoxShadow={false}
						iconLeft='ellipsis-stroke'
						items={getButtonItemsOfferActions(configurationOfferActions as ButtonProps[])}
						style={{
							bottom: '150%',
							left: '-58%'
						}}
						theme={EnumTheme.NAKED}
					/>
				)}
				{configurationActionBar?.actions}
			</ActionBar>
		</div>
	) : null;

	const historyParagraphCss = [
		styles.paragraph,
		styles.history
	];

	const nbItemsToDisplayed = isDesktopResolution ? 6 : 3;

	const historyElement = isEventHistoryLoaded ? (
		<Paragraph
			className={historyParagraphCss.join(' ')}
			title={t('format.capitalize', {
				text: isPrivate ? t('page.demand.offer.detail.announcement_history') : t('page.demand.offer.detail.referencial')
			})}
		>
			{referencialMandateElement}

			{isPrivate && data?.event_history?.collection?.length ? (
				<>
					<ul className={styles.list}>
						{(data.event_history.collection).slice(0, nbItemsToDisplayed).map((item, key: number) => {
							return (
								<li
									className={styles.list__element}
									key={key}
								>
									{t('format.capitalize', {
										text: t(`page.demand.${item.event}`, {
											'%date%': item.date
										})
									})}
								</li>
							);
						})}
					</ul>

					{data.event_history?.collection?.length > 6 ? (
						<Button
							className={styles.button}
							corners={EnumButtonCorners.Square}
							label={t('format.capitalize', {
								text: t('page.demand.offer.detail.view_all_history')
							})}
							size={EnumButtonSize.SMALL}
							theme={EnumTheme.SECONDARY}
							onClick={(event) => handleOnClickTriggerModal(event, {
								hasHeader: true,
								title: t('format.capitalize', {
									text: isPrivate ? t('page.demand.offer.detail.announcement_history') : t('page.demand.offer.detail.referencial')
								}),
								size: EnumModalSize.DEFAULT
							} as ModalPropsState)}
						/>
					) : null}
				</>
			) : null}

		</Paragraph>
	) : <ContentLoaderHistory />;

	return (
		<div className={cssClasses.join(' ')}>
			{isContentLoaded?.offer ? (
				<>
					{isDesktopResolution ? offerDetailsTitleAddress : null}
					{isDesktopResolution ? offerDetailsTitle : null}
					<div className={styles.PageOfferDetails__header}>
						{sliderElement}
						{isDesktopResolution ? featuresListElement : null}
					</div>
				</>
			) : <ContentLoaderSlider />}
			{historyElement}
			{descriptionElement}
			{servicesElement}
			{financialTermsElement}
			{legalTermsElement}
			{areasElement}
			{mandatedAgentsElement}
			{energyPowerDiagnosticElement}
			{transportAccessElement}
			{modalElement}
			{isDesktopResolution ? null : cartographyElement}
			{configurationActionBar?.actions ? actionSelectedBarElement : undefined}
		</div>
	);
}

export default withTranslation()(PageOfferDetails);
